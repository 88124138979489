/** 
 * @Desc: 个人中心
 */
<template>
  <div class="basic-info-wrapper card-wrapper">
    <img src="../../assets/new_ui/personal_ip.png" alt="" class="img-bottom-bg">
    <img src="../../assets/new_ui/personal_ip_left.png" alt="" class="img-bottom-bg" style="left: -0.5rem;">
    <el-form label-position="right" :model="infoForm" :rules="ruleForm" ref="ruleForm" class="self-el-form">
      <el-form-item label="手 机 号：" prop="phone">
        <el-input v-model.trim="infoForm.phone" placeholder="请输入手机号（平台登录账号）" ref="accountPhone" :maxlength="11"
          v-enterNumber disabled></el-input>
      </el-form-item>
      <el-form-item label="昵  称：" prop="nickname">
        <el-input v-model.trim="infoForm.nickname" ref="pageInput" @blur="changeNickName" placeholder="请输入昵称"
          :maxlength="20" :disabled="isNickName"></el-input>
        <div class="penIcon" @click="updataNickName"><img src="../../assets/pen.jpg" alt=""></div>
      </el-form-item>
      <!--  <el-form-item label="姓名：" prop="parentName">
        <el-input v-model.trim="infoForm.parentName" placeholder="请输入姓名" :maxlength="10"></el-input>
      </el-form-item> -->
      <el-form-item label="已绘画张数：" prop="paintingUsedCount">
        <el-input v-if="vipType == 0" :value="infoForm.paintingUsedCount + ' 张 '" disabled></el-input>
        <el-input v-if="vipType == 1" :value="infoForm.paintingUsedCount + ' 次 '" disabled></el-input>
      </el-form-item>
      <el-form-item label="剩余绘画张数：" prop="remainingPaintingCount">
        <el-input v-if="vipType == 0" :value="infoForm.remainingPaintingCount + ' 张 '" disabled></el-input>
        <el-input v-if="vipType == 1" :value="infoForm.remainingPaintingCount + ' 次 '" disabled></el-input>
      </el-form-item>
    </el-form>
    <!-- <div class="bottom-btns">
      <el-button type="primary" size="small" :loading="okLoading" @click="!okLoading && _save()">保存</el-button>
    </div> -->
  </div>
</template>
<script>
import store from "store";
import { mapState } from "vuex";
import { localStorageUtil, validataPhone, debounce } from "utils/util";
import { reqUpdateInformation,reqGetInformation } from "api/personal-center";
import { upDataUser } from '@/api/resource.js'

export default {
  data() {
    const nicknameR = (rule, val, callback) => {
      console.log(val)
      if (this.okLoading && !val) {
        return callback(new Error("请输入昵称"));
      } else if (!val) {
        return callback();
      }
      if (val.length > 30 || val.length < 2) {
        return callback(
          new Error("昵称2-20个字符，可由中英文、数字、“_”、“-”组成")
        );
      }
      // let reg = /^[\u4e00-\u9fa5a-zA-Z0-9_-]{2,20}$/g;
      // if (!reg.test(val)) {
      //   return callback(
      //     new Error("昵称2-20个字符，可由中英文、数字、“_”、“-”组成")
      //   );
      // }
      callback();
    };
    const parentNameR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请输入姓名"));
      } else if (!val) {
        return callback();
      }
      if (val.length > 10 || val.length < 2) {
        return callback(new Error("姓名仅支持2-10个字符"));
      }
      let reg = /^[\u4e00-\u9fa5]{2,20}$/g;
      if (false && !reg.test(val)) {
        return callback(new Error("姓名2-20个字符，仅支持中文"));
      }
      callback();
    };
    const genderR = (rule, val, callback) => {
      if (this.okLoading && !val) {
        return callback(new Error("请选择性别"));
      }
      if (!val) {
        return callback();
      }
      callback();
    };
    return {
      vipType: '',
      oldVal: '',
      isNickName: true,
      okLoading: false,
      infoForm: {
        phone: "",
        nickname: "",
        paintingUsedCount: 0,
        gender: null
      },
      ruleForm: {
        nickname: [{ trigger: "blur", validator: nicknameR }],
        parentName: [{ trigger: "blur", validator: parentNameR }],
        gender: [{ trigger: "change", validator: genderR }]
      }
    };
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  watch: {
    accountInfo: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.infoForm = Object.assign({}, val);
          this.loading = false;
        }
      }
    }
  },
  methods: {
    /**
     * 保存
     * **/
    _save: debounce(function () {
      this.okLoading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this._updateInfo();
        } else {
          this.okLoading = false;
        }
      });
    }),
    /**
     * 获取基本资料
     * **/
    _getInfo() {
      let obj = {};
      console.log('获取用户信息')
      return reqGetInformation(obj)
        .then(res => {
          if (res.code === 0) {
            let _info = res.data;
            this.infoForm = Object.assign(this.accountInfo, _info);
            this.vipType = this.infoForm.vipType;
            console.log(this.infoForm)
            store.commit("SET_USER",  this.infoForm);
          } else {
            this.$message({
              type: "error",
              message: "基本资料获取失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          console.log("获取基本资料失败", err);
        });
    },
    /**
     * 更新基本资料
     * **/
    _updateInfo() {
      let obj = {
        nickname: this.infoForm.nickname,
        parentName: this.infoForm.parentName,
        gender: this.infoForm.gender
      };
      return reqUpdateInformation(obj)
        .then(res => {
          this.okLoading = false;
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "基本资料已更新",
              duration: 2000
            });
            let _info = store.state.accountInfo;
            store.commit("SET_USER", Object.assign({}, _info, obj));
          } else {
            this.$message({
              type: "error",
              message: "基本资料更新失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          this.okLoading = false;
          console.log("基本资料更新失败", err);
        });
    },
    //修改昵称
    updataNickName() {
      this.isNickName = false;
      this.$nextTick(() => {
        this.$refs.pageInput.focus()
        this.oldVal = this.infoForm.nickname
      })
    },
    //鼠标失去焦点及按下回车
    async changeNickName() {
      if (this.infoForm.nickname == '') {
        this.$message.warning('昵称不能为空')
        this.$refs.pageInput.focus()
        this.infoForm.nickname = this.oldVal
      }
      if (this.okLoading && !this.infoForm.nickname.trim) {
        return new Error("请输入昵称");
      } else if (!this.infoForm.nickname) {
        return;
      }
      if (this.infoForm.nickname.length > 30 || this.infoForm.nickname.length < 2) {
        return new Error("昵称2-20个字符，可由中英文、数字、“_”、“-”组成")
      }
      // let reg = /^[\u4e00-\u9fa5a-zA-Z0-9_-]{2,20}$/g;
      // if (!reg.test(this.infoForm.nickname)) {
      //   return new Error("昵称2-20个字符，可由中英文、数字、“_”、“-”组成")

      // }
      if (this.infoForm.nickname.trim) {
        if (this.oldVal == this.infoForm.nickname) {
          this.$refs.pageInput.focus()
        } else {
          let nickname = this.infoForm.nickname
          let res = await upDataUser({ nickname })
          this.$message.success('修改成功')
        }
        this.isNickName = true;
      } else {
        this.$message.warning('请输入正确的昵称')
        this.$refs.pageInput.focus()
      }
      this.$set(this.accountInfo, 'nickname', this.infoForm.nickname)
      store.commit("SET_USER", Object.assign({}, this.accountInfo));
    }
  },
  created() {
    this._getInfo()
  }
};
</script>
<style lang="less">
.basic-info-wrapper {
  .self-el-form {
    padding-top: .5rem;
    // padding-left: 2.45rem;
    // padding-right: 2.63rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;

    .penIcon {
      width: 1vw;
      height: 1vw;
      position: absolute;
      top: .5vw;
      right: -2vw;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .el-form-item__label {
      min-width: 1.8rem;
      // width: 1.1rem;
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
      text-align: right;
      text-align-last: right;
    }

    .el-input__icon {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-right: 0.05rem;
    }

    .el-form-item {
      margin-top: 0.3rem;
      margin-bottom: 0;
    display: flex;
    justify-content: space-around;
    // flex-direction: column;
    align-items: center;

      .el-form-item__content {
        line-height: 0;

        .el-input {
          width: 3.68rem;
          height: 0.44rem;
          font-size: 0.14rem;
          line-height: .44rem;

          .el-input__inner {
            height: 0.4rem;
            line-height: .2rem;
            color: #579138;
          }

          .el-input__inner[disabled*=disabled] {
            color: #579138;
            opacity: 1;
            -webkit-text-fill-color: #579138;
          }
        }
      }
    }
  }

  .el-select .el-input .el-input__suffix {
    height: 0.4rem;
    line-height: 0.4rem;
  }

  .bottom-btns {
    width: 100%;
    height: 0.44rem;

    .el-button {
      width: 3.38rem;
      height: 0.44rem;
      margin-left: 3.61rem;
      margin-right: 2.87rem;
      font-size: 0.16rem;
      line-height: 0.44rem !important;
    }
  }

  .self-el-form {
    padding-bottom: 0;
  }
}

.el-input.is-disabled .el-input__inner {
  background: #c2eda6 !important;
}

.basic-info-wrapper {}

// @media (max-width: 2560px) {
//   .el-select-dropdown__item {
//     font-size: 0.14rem !important;
//     height: 0.34rem !important;
//     line-height: 0.34rem !important;
//   }
// }
// @media (max-width: 3840px) {
//   .el-select-dropdown__item {
//     font-size: 0.14rem !important;
//     height: 0.34rem !important;
//     line-height: 0.34rem !important;
//   }
// }
</style>