<template>
  <div class="left-menu-wrapper">
    <div class="avatar-box" :class="accountInfo ? '' : 'avatar-box-gray'">
      <img v-if="accountInfo" src="../../assets/new_ui/avatar_personal.png" alt="">
      <img
        v-else
        src="../../assets/new_ui/avatar_personal_gray.png"
        alt=""
        class="cursor"
        @click="$emit('handlerLogin')"
      >
      <p
        :class="accountInfo ? 'no-wrap' : 'cursor'"
        @click="!accountInfo && $emit('handlerLogin')"
      >{{accountInfo ? accountInfo.nickname : '登录'}}</p>
    </div>
    <!-- <p class="line"></p> -->
    <div class="personal-menu-box">
      <div class="personal-center-menu">
        <div
          v-for="(menu, index) in menuList"
          :key="index"
          :name="index"
          :class="{
              'menu-item': true,
              'select-menu': $route.path.indexOf(menu.haveIndexOf) !== -1
            }"
          @click="routerChange(menu, index)"
        >
          <span class="img-box">
            <img :src="menu.icon_img" alt="" class="img-menu">
          </span>
          {{ menu.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "LeftMenu",
  data() {
    return {
      isSelect: 0,
      menuListOriginal: [
        {
          title: "个人中心",
          path: "/personal-center/basic-info",
          haveIndexOf: "/personal-center/basic-info",
          icon_img: require("../../assets/new_ui/icon-personal.png")
        },
        // {
        //   title: "最近观看",
        //   path: "/personal-center/history-record",
        //   haveIndexOf: "/personal-center/history-record",
        //   icon_img: require("../../assets/new_ui/icon-history.png")
        // },

        // {
        //   title: "我的收藏",
        //   path: "/personal-center/collection",
        //   haveIndexOf: "/personal-center/collection",
        //   icon_img: require("../../assets/new_ui/icon-collection.png")
        // },
        {
          title: "绘画记录",
          path: "/personal-center/painting-record",
          haveIndexOf: "/personal-center/painting-record",
          icon_img: require("../../assets/new_ui/icon-age-group.png")
        },
        {
          title: "修改密码",
          path: "/personal-center/change-pwd",
          haveIndexOf: "/personal-center/change-pwd",
          icon_img: require("../../assets/new_ui/icon-pwd.png")
        }
      ],
      menuList: [],
      productType: 1
    };
  },
  computed: {
    ...mapState(["accountInfo", "isLogin"])
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, oldVal) {
        let fullPath = val.fullPath;
        if (this.$route.query.redirect) {
          fullPath = this.$route.query.redirect;
        }
        for (let i = 0; i < this.menuList.length; i++) {
          if (fullPath.indexOf(this.menuList[i].haveIndexOf) !== -1) {
            this.isSelect = i;
          }
        }
        this.$router.push(fullPath);
        setTimeout(() => {
          if (val.path === "/personal-center") {
            this.$router.push(this.menuList[0].path);
          }
        }, 100);
      }
    },
    isLogin: {
      immediate: true,
      handler(val) {
        if (val) {
          this.menuList = [...this.menuListOriginal];
        } else {
          this.menuList = [...this.menuListOriginal];
        }
      }
    }
  },
  methods: {
    routerChange(item, index) {
      !this.isLogin? this.$emit('handlerLogin'):
      this.isSelect = index;
      this.$router.push(item.path);
    }
  },
  created() {}
};
</script>
<style lang="less">
.left-menu-wrapper {
  // margin-right: 22px;
  margin-right: 0.8rem;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  // margin-bottom: 20px;
  // height: calc(100vh - 300px);
  // min-height: 500px;
  // max-height: 700px;
  // width: 240px;
  width: 2.8rem;
  height: 100%;
  background: #fff;
  border-radius: 40px;
  position: relative;
  .avatar-box {
    width: 100%;
    height: 1.84rem;
    // height: 190px;
    border-radius: 6px;
    text-align: center;
    background: #ade787;
    border-radius: 40px 40px 0 0;

    img,
    span {
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      margin-top: 0.36rem;
    }
    p {
      color: #46851b;
      font-size: 0.16rem;
      padding: 0 20px;
    }
    &.avatar-box-gray {
      // img {
      //   margin-top: 150px;
      // }
      p {
        color: #6e6e6e;
      }
    }
  }
  .line {
    width: 230px;
    height: 1px;
    background: #f4d383;
    margin: 20px 5px;
  }
  .personal-menu-box {
    margin-bottom: 20px;
    margin-top: 30px;
    .personal-center-menu {
      padding: 0 27px;
      .menu-item {
        margin-top: 0.17rem;
        height: 0.4rem;
        line-height: 0.4rem;
        // padding-left: 38px;
        text-align: center;
        font-size: 0.16rem;
        color: #448816;
        cursor: pointer;
        border-radius: 7px;
        .img-box {
          display: inline-block;
          vertical-align: top;
          width: 25px;
          height: 25px;
          width: 0.25rem;
          height: 0.25rem;
          text-align: center;
          margin-right: 12px;
          margin-top: 7.5px;

          margin-right: 0.12rem;
          margin-top: 0.07rem;
          .img-menu {
            height: 25px;
            height: 0.25rem;
            display: inline-block;
            vertical-align: top;
          }
        }
        &.select-menu {
          // width: 187px;
          height: 0.4rem;
          background: #e3facd;
          // text-shadow: 0px 2px 1px #448816;
          box-shadow: 0px 2px 1px #eff8e8;
        }
        &:not(.select-menu):hover {
          background: #eff8e8;
          animation: none;
        }
      }
    }
  }
}
</style>